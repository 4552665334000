/**
 * @description
 *
 * Возвращает отступ элемента от верхнего края документа или относительно переданного родительского элемента.
 *
 * @param { Element } el - элемент для вычисления
 * @param { Element= } parent - элемент, относительно которого вычисляется отступ
 *
 * @return { Number }
 */

function getElementOffsetTop(el, parent) {
  const rect = el.getBoundingClientRect()
  const docScrollTop = parent
    ? parent.scrollTop
    : window.pageYOffset || document.documentElement.scrollTop
  const clientTop = parent
    ? parent.getBoundingClientRect().top
    : document.documentElement.clientTop || 0

  return rect.top + docScrollTop - clientTop
}

export default getElementOffsetTop
