function getFormatPhoneNumber(phone: string): string {
  // Удаляем все нечисловые символы
  let digits: string = phone.replace(/\D/g, '')

  // Определяем код страны и форматирование для разных стран
  const countryFormats: Record<number, { code: string; format: string }> = {
    7: { code: '+7', format: 'XXX XXX-XX-XX' }, // Россия, Казахстан
    374: { code: '+374', format: 'XX XXX-XXX' }, // Армения
    375: { code: '+375', format: 'XX XXX-XX-XX' }, // Беларусь
    380: { code: '+380', format: 'XX XXX-XX-XX' }, // Украина
    996: { code: '+996', format: 'XXX XXX-XXX' }, // Кыргызстан
    998: { code: '+998', format: 'XX XXX-XX-XX' }, // Узбекистан
    995: { code: '+995', format: 'XXX XX-XX-XX' }, // Грузия
    992: { code: '+992', format: 'XX XXX-XX-XX' }, // Таджикистан
    993: { code: '+993', format: 'X XXX-XX-XX' }, // Туркменистан
    994: { code: '+994', format: 'XX XXX-XX-XX' }, // Азербайджан
  }

  let countryCode: string = ''
  let numberFormat: string = ''

  Object.keys(countryFormats)
    .map(Number) // Преобразуем строки в числа
    .sort((a, b) => b - a) // Сортировка нужна, чтобы сначала проверять более длинные коды стран, прежде чем проверять короткие. Это предотвращает ошибочное совпадение с короткими кодами.
    .some(code => {
      const codeStr: string = code.toString()

      if (digits.startsWith(codeStr)) {
        countryCode = countryFormats[code].code
        numberFormat = countryFormats[code].format
        digits = digits.slice(codeStr.length) // Обрезаем код страны
        return true // Прерываем итерацию
      }

      return false
    })

  // Если страна не найдена, просто возвращаем номер без форматирования
  if (!countryCode) {
    return `+${digits}`
  }

  let formattedNumber: string = ''
  let digitIndex: number = 0

  numberFormat.split('').forEach(char => {
    if (char === 'X' && digitIndex < digits.length) {
      formattedNumber += digits[digitIndex]
      digitIndex++
    } else {
      formattedNumber += char
    }
  })

  // Проверяем, остались ли лишние цифры
  const extraDigits: string = digits.slice(digitIndex)

  if (extraDigits.length > 0) {
    return `${countryCode}${digits}` // Возвращаем без форматирования
  }

  return `${countryCode} ${formattedNumber}`.trim()
}

export default getFormatPhoneNumber
